import React, { useState } from 'react'
import DownArrow from '../assets/pic/arrow-down-icon.svg'
import { Text } from '../languages/Language';
import {URL as NEWURL} from '../constants';
import {VENDORID as NEWVENDORID} from '../constants';

export default function GetCustomerCode(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [data, setData] = useState(false);

    const handleSubmit = async event => {
        setShowModal(false)
        sessionStorage.setItem('customerCode', event);
        props.getCustomerCode(event)
    }

    function  handleSetCustomerCode() {
        const url = NEWURL;

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json' },
            body: JSON.stringify({
                "serviceId": "1116", //service for GetCustomerCode
                "vendorId": NEWVENDORID,
                "userEmail": props.email,
                "password": props.password,
                "lang": localStorage.getItem('rcml-lang')
            })

        };

        const fetchData = async () => {
            try {

                const response = await fetch(url, requestOptions);
                const json = await response.json();
                setData(json)
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();

setShowModal(true)
    }


    return (
        <>
            <button
                type="button"
                onClick={() => handleSetCustomerCode()}
            >
                <img src={DownArrow} className="w-4 h-4 mx-4" alt='' />
            </button>

            {showModal ? (
                <center>
                    <div
                        className="w-full top-1/2 h-auto fade In text-company-primary-color justify-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto h-auto overflow-y-auto max-w-3xl">
                            {/*content*/}
                            <div className=" overflow-y-auto p-3 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-base font-semibold text-slate-700">
                                    <Text tid="ChooseCustomerCode" />
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-50 float-right text-base leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-base block outline-none focus:outline-none">
                                            &#10006;
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative px-6 flex-auto">
                                    <div className=" text-slate-500">
                                        { /*----------------------------------------------------------------------------*/}

                                        <div className=" flex items-center justify-center">

                                            <div className="max-w-md w-full space-y-4">
                                            {!data ?  <div className="flex w-full items-center justify-center"><Text tid="Loading" /></div>:null }
                                                {data && data.resCode !== 200 ? <div className="flex w-full items-center justify-center"><Text tid="Error" />: {data.resMessage}</div>:null }

                                                {data.dataSet ? data.dataSet.map((item, itemCode) =>
                                                (
                                                    <div key={itemCode} className="flex flex-wrap flex-col cursor-pointer" onClick={() => handleSubmit(item.customerCode)}>
                                                        <p>{item.customerCode}</p>
                                                        <p>{item.customerName}</p>

                                                    </div>
                                                )

                                                ):null}

                                            </div>
                                        </div>
                                        {/*-----------------------------------------------------------------------------------------*/}
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
                </center>
            ) : null}</>

    )
}
