import { URL as NEWURL } from '../constants';
import { VENDORID as NEWVENDORID } from '../constants';

export default function fetchItemsFromAPI(token) {

    const url = NEWURL;

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": "2015",
            "token": token,
            "slipRef": sessionStorage.getItem('slipRef'),
            "invoiceStatus": 0, //getAll gerekli mi?
            "slipStatus": 1, //0: Aktif Sepet/Slip; 1: Kayitli Sepet/Slip; 2: Gönderilmis Sepet/Slip; 3: Silinen Sepet/Slip
            "vendorId": NEWVENDORID
        })
    };

    const fetchData = async () => {
        try {
            console.log("2015")
            const response = await fetch(url, requestOptions);
            const json = await response.json();
            if (json.resCode === 200) {
                console.log(requestOptions)

                    sessionStorage.setItem('slipRef', json.slip[0]?.slipRef);
                    sessionStorage.setItem('items', JSON.stringify(json.slipLines));


                if (token === 1306) {
                    sessionStorage.setItem('allCategories', JSON.stringify(json.dataSet));
                }
                return json;
            }
            else return;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}
