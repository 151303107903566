import { Text, TextToStr } from '../languages/Language';
import {roundDigits} from "../utils";
import { CURRENCY as NEWCURRENCY, DIGITS, IMAGEURL } from '../constants';
import OrderItems from "./OrderItems";
import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import StepWizard from "react-step-wizard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faCheck, faCreditCard, faShoppingCart } from "@fortawesome/free-solid-svg-icons";




function DetailedBasket(props) {
  const [stepWizard, setStepWizard] = useState(null);
  const [activeStep, setActiveStep] = useState(2);
  const [activeTab, setActiveTab] = useState('creditCard');
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [data, setData] = useState(null);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [orderNo, setOrderNo] = useState("");


  const assignStepWizard1 = (instance) => {
    setStepWizard(instance);
  };

  const handleStepChange1 = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete1 = () => {
    alert("You r done. TQ");
  };


    const discRate = sessionStorage.getItem('discRate');
    const itemsPrice = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * c.itemAmount, 0);

    const itemsPriceTax = props.cartItems?.reduce((a, c) =>
        a + c.itemMainUnitVpe
        * (c.itemCampaignPrice ? c.itemCampaignPrice?.toFixed(DIGITS) : c.itemPrice?.toFixed(DIGITS))
        * (c.itemCampaignPrice ? 1 : (1 - (!Number.isNaN(discRate) && discRate > 0 ? discRate / 100 : 0)))
        * (1 + c.itemVat / 100)
        * c.itemAmount, 0);

   // const [showModalForItem, setShowModalForItem] = React.useState(false);
    const [Expl1, setExpl1] = React.useState('');
    const [Expl2, setExpl2] = React.useState('');

    React.useEffect(() => {
        const storedExpl1 = sessionStorage.getItem('Expl1');
        const storedExpl2 = sessionStorage.getItem('Expl2');
        setData(JSON.parse(sessionStorage.getItem('user')));
        if (storedExpl1) {
            console.log(storedExpl1)
            setExpl1(storedExpl1);
        }
        if (storedExpl2) {
            setExpl2(storedExpl2);
        }
    }, [sessionStorage.getItem('Expl1'), sessionStorage.getItem('Expl2'),sessionStorage.getItem('user')]);

    const handleChangeExpl1 = (event) => {
        setExpl1(event.target.value);
        sessionStorage.setItem('Expl1', event.target.value);
    };

    const handleChangeExpl2 = (event) => {
        setExpl2(event.target.value);
        sessionStorage.setItem('Expl2', event.target.value);
    };

    const handleDelete = () => {
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        setExpl1('');
        setExpl2('');
    };

  const validate = () => {
      stepWizard.nextStep();
  };

  const validate2 = () => {
      stepWizard.previousStep();
  };

  const validate3 = () => {
    props.ShowDetailedBasket(false)
    setActiveStep(0)
      stepWizard.lastStep();
  };

  const transit = {
    enter: '',
    exit: ''
  };

    return (
        <>
          {props.detailedBasket && sessionStorage.getItem("token") ? (
            <>
              <div className="text-slate-600 h-full fade In justify-center items-center flex overflow-hidden fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="flex w-11/12 md:w-5/6 xl:w-3/5 my-auto mx-auto min-h-[75%] max-h-[95%] ">
                  {/*content*/}
                  <div className="overflow-auto border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white p-2">
                    {/*header*/}
                    <div className="flex flex-col">
                    <div className="sticky top-0 flex flex-row gap-3 justify-between p-1 border-b border-solid border-slate-200 rounded-t w-full h-18 z-10">
                      <button
                        className="border-0 text-black flex items-center h-6 opacity-50 text-3xl font-semibold outline-none focus:outline-none"
                        onClick={() => {
                          props.ShowDetailedBasket(false)
                          setActiveStep(0)
                        }}
                      >
                        <span className="bg-transparent text-black h-6 w-6 text-xl flex items-centers justify-center outline-none focus:outline-none">
                          &#10006;
                        </span>
                      </button>
                      <h3 className="text-lg font-semibold text-slate-700 h-10">
                        <Text tid="ShoppingCart" />
                      </h3>
                      <div className="ml-auto flex flex-row gap-5 z-50">

                        <OrderItems
                          cartItems={props.cartItems}
                          onSlipDeleteAllLines={() => {
                            props.onSlipDeleteAllLines();
                            props.ShowDetailedBasket(false);

                            handleDelete();
                          }}
                          errorModal ={(bool)=> setErrorModal(bool)}
                          errorText ={(text)=> setErrorText(text)}
                          showToast={(type) => props.showToast(type)}
                          showSuccess = {(bool)=> setShowSuccessNotification(bool)}
                          orderNo = {(text)=> setOrderNo(text)}
                        />

                      </div>
                    </div>
                    <Stepper activeStep={2}>
                      <Step children={<FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>} />
                      <Step children={<FontAwesomeIcon icon={faCreditCard}></FontAwesomeIcon>} />
                      <Step children={<FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>} />
                    </Stepper>
                    </div>
                    {/*body*/}
                    <div className="h-full overflow-y-auto overflow-x-hidden">

                {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
                    <StepWizard transitions={transit} instance={assignStepWizard1} onStepChange={handleStepChange1}>
{/*Onaylama Ekranı */}
<div >
<div className=' flex flex-col lg:flex-row items-center px-4 pb-3 gap-1'>
                                    <div className='flex flex-row w-full items-center justify-evenly'>
                                        <label
                                            htmlFor="Explanation1" className="text-sm items-center whitespace-nowrap overflow-hidden text-ellipsis"
                                        >
                                            <Text tid="Explanation1" />:
                                        </label>

                                        <input
                                            id="Explanation1"
                                            name="Explanation1"
                                            type="text"
                                            value={Expl1}
                                            onChange={handleChangeExpl1}
                                            autoComplete="Explanation1"
                                            maxLength={51}
                                            className="appearance-none mx-1 rounded w-3/5 sm:w-3/4 h-8 border"
                                        />
                                    </div>
                                    <div  className='flex flex-row w-full items-center justify-evenly'>
                                        <label
                                            htmlFor="Explanation2" className="text-sm items-center whitespace-nowrap overflow-hidden text-ellipsis"
                                        >
                                            <Text tid="Explanation2" />:
                                        </label>

                                        <input
                                            id="Explanation2"
                                            name="Explanation2"
                                            type="text"
                                            value={Expl2}
                                            onChange={handleChangeExpl2}
                                            autoComplete="Explanation2"
                                            maxLength={51}
                                            className="appearance-none mx-1 rounded  w-3/5 sm:w-3/4 h-8 border"
                                        />
                                    </div>
                                </div>
<div className="px-4 flex justify-center">

<table className="overflow-auto w-full  md:text-lg table-auto text-slate-600">
    <thead className=" bg-white sticky top-0 font-thin">
      <tr className="text-xs text-start text-white bg-company-primary-color">

        <th className="font-normal text-start hidden md:block px-0.5">
          <Text tid="ProductCode" />
        </th>
        <th className="font-normal text-start px-1">
          <Text tid="ProductName" />
        </th>
        <th className="font-normal text-end">
          <Text tid="ProductCount" />
        </th>
        <th className="font-normal text-center">
        <Text tid="Unit" />
        </th>
        <th className="font-normal text-end">
        <Text tid="PackagingUnit" />
        </th>
        <th className="font-normal text-end">
        <Text tid="PiecePrice" />
        </th>
        <th className="font-normal text-end hidden md:block">
          <Text tid="NetAmount" />
        </th>
        <td></td>
        <th className="font-normal text-end hidden md:block px-1">
          <Text tid="VAT" />
        </th>

      </tr>
    </thead>
    <tbody className="overflow-y-auto">
      {props.cartItems
        ? props.cartItems.map((record, index) => {
            return (
              <tr key={index} className="odd:bg-white even:bg-slate-200">

                <td className="text-xs hidden md:block px-0.5">{record.itemCode}</td>
                <td className="text-start text-xs px-1">{record.itemName1}</td>
                <td className='text-xs text-end'>
                {record.itemAmount}
                </td>
                <td className='text-xs text-center'>
                {record.itemUnitCode}
                </td>
                <td className='text-xs text-end'>
                {record.itemMainUnitVpe}
                </td>
                <td className='text-xs text-end'>
                {roundDigits(record.itemPrice.toFixed(DIGITS))} {NEWCURRENCY}
                </td>
                <td className='text-xs text-end hidden md:block'>
                  {roundDigits(
                    record.itemMainUnitVpe *
                      (record.itemCampaignPrice ? 1 : 1 - discRate / 100) *
                      record.itemAmount *
                      (record.itemCampaignPrice
                        ? record.itemCampaignPrice.toFixed(DIGITS)
                        : record.itemPrice.toFixed(DIGITS))
                  )}
                  &nbsp;{NEWCURRENCY}
                </td>
                <td></td>
                <td className='text-xs text-end hidden md:block px-1'>{record.itemVat}%</td>

              </tr>
            );
          })
        : null}
    </tbody>
  </table>
</div>

          <div className="flex text-xs w-full flex-col-reverse lg:flex-row p-4 lg:justify-between sticky bottom-0 bg-white">

            <div className="text-left mt-2 sm:mt-0">
              <p className="font-bold">{data[0].erpCustomerName1}</p>
              <p>{data[0].erpShippingAdrName2} {data[0].erpShippingAdrAddress1} {data[0].erpShippingAdrAddress2} {data[0].erpShippingAdrPostalCode} {data[0].erpShippingAdrCityName} {data[0].erpShippingAdrCountryCode} {data[0].erpShippingAdrCountryName}</p>

            </div>

            <div className="flex text-xs justify-end pr-0 sm:pr-2 md:pr-2 lg:pr-5 xl:pr-7 2xl:pr-10">
              <table>
                <tr className="justify-between">
                  <td className="pr-8"><Text tid="NetAmount" /></td>
                  <td className="w-6">:</td>
                  <td className="text-right">{roundDigits(itemsPrice?.toFixed(DIGITS))}&nbsp;{NEWCURRENCY}</td>
                </tr>
                <tr className="justify-between">
                  <td><Text tid="VAT" /></td>
                  <td>:</td>
                  <td className="text-right">{roundDigits((itemsPriceTax - itemsPrice)?.toFixed(DIGITS))}&nbsp;{NEWCURRENCY}</td>
                </tr>
                <tr className="justify-between">
                  <td className="font-bold"><Text tid="TotalAmount" /></td>
                  <td>:</td>
                  <td className="font-bold text-right">{roundDigits(itemsPriceTax?.toFixed(DIGITS))}&nbsp;{NEWCURRENCY}</td>
                </tr>
              </table>
              </div>
          </div>
</div>
                      </StepWizard>

                      </div>
                      <div>

                      </div>
                  </div>
                </div>
              </div>
              <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}





          {errorModal?
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center"
                                >
                                    <Text tid="Error" />
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="relative p-6 flex-auto"
                      >
                          <div
                              className="text-center">
                              {errorText}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setErrorModal(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        :null}

            {
        showSuccessNotification && (
          <>
          <div
              className="justify-center items-center flex overflow-x-hidden
overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
              <div
                  className="relative w-auto my-6 mx-auto max-w-3xl"
              >
                  {/*content*/}
                  <div
                      className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                      {/*header*/}
                      <div
                                className=" flex items-start text-center justify-center p-5 border-b border-solid border-slate-200 rounded-t"
                            >
                                <h3
                                    className="text-lg font-semibold text-center"
                                >
                                    <Text tid="Successful" />
                                </h3>

                            </div>
                      {/*body*/}
                      <div
                          className="relative p-6 flex-auto"
                      >
                          <div
                              className="text-center">
                              <Text tid="OrderSuccess"/> {orderNo}
                              <div className='flex flex-row justify-center mt-6'>
                                  <button
                                  onClick={() => setShowSuccessNotification(false)}
                                      className="flex min-w-[8rem] items-center justify-center rounded-md border border-transparent bg-company-primary-color px-3 py-1 text-base font-medium text-white shadow-sm hover:opacity-75"
                                      type="button"

                                  >
                                      <Text tid="Ok" />
                                  </button>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div
              className="opacity-50 fixed inset-0 z-40 bg-black"
          >
          </div>
      </>
        )
      }


        {
        /*
        showSuccessNotification && (
          <div className="flex justify-center items-center">
            <div className="fixed text-center bottom-16 w-auto bg-green-500 z-50 text-white py-3 px-5 rounded-lg shadow-md">
                    <Text tid="Successful"/>
                </div>
          </div>

            )
          */
            }
        </>
      );

}

export default DetailedBasket